<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">ข้อมูลลูกค้า</h4>

            <div class="row mt-3">
              <form-wizard color="#5b73e8" step-size="sm">
                <tab-content icon="mdi mdi-numeric-1" title="ข้อมูลส่วนตัว">
                  <div class="row">
                    <div class="col-sm-6 col-md-6 col-xl-5">
                      <div class="mb-3 position-relative">
                        <code>* </code><label for="branchId">สาขา:</label>
                        <multiselect
                          id="branchId"
                          v-model="form.branchId"
                          :options="localData"
                          :show-labels="false"
                          label="nameTh"
                          track-by="nameTh"
                          @input="getSearch()"
                          :class="{
                            'is-invalid': submitform && $v.form.branchId.$error,
                          }"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span></multiselect
                        >
                        <div
                          v-if="submitform && $v.form.branchId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.form.branchId.required">{{
                            error
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3 col-xxl-3">
                      <div class="mb-3 position-relative">
                        <code>* </code
                        ><label for="mobilePhone">เบอร์โทรศัพท์:</label>
                        <input
                          maxlength="10"
                          v-model="form.mobilePhone"
                          type="tel"
                          class="form-control input-placeholder"
                          placeholder="เบอร์โทรศัพท์แบบไม่มีขีด"
                          :class="{
                            'is-invalid':
                              submitform && $v.form.mobilePhone.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.form.mobilePhone.$error"
                          class="invalid-tooltip"
                        >
                          <span
                            v-if="
                              !$v.form.mobilePhone.required ||
                              !$v.form.mobilePhone.numeric ||
                              !$v.form.mobilePhone.maxLength ||
                              !$v.form.mobilePhone.minLength
                            "
                            >{{ error }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 col-md-3 col-xl-2">
                      <div class="mb-3 position-relative">
                        <label for="gender"> เพศ:</label>
                        <multiselect
                          id="gender"
                          v-model="form.gender"
                          :options="genderOption"
                          :show-labels="false"
                          label="nameTh"
                          track-by="nameTh"
                          placeholder=""
                        ></multiselect>
                      </div>
                    </div>
                    <div class="col-sm-4 col-md-3 col-xl-2">
                      <div class="mb-3 position-relative">
                        <label for="prefixOPtion">คำนำหน้า:</label>
                        <multiselect
                          placeholder=""
                          @input="ChangeNameTh(form.prefixId)"
                          v-model="form.prefixId"
                          :options="prefixOPtion"
                          label="nameTh"
                          :show-labels="false"
                        >
                        </multiselect>
                      </div>
                    </div>

                    <div class="col-sm-6 col-md-3 col-xl-2">
                      <div class="mb-3 position-relative">
                        <code>* </code><label for="nameTh">ชื่อ:</label>
                        <input
                          v-model="form.nameTh"
                          type="text"
                          class="form-control"
                          placeholder=""
                          :class="{
                            'is-invalid': submitform && $v.form.nameTh.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.form.nameTh.$error"
                          class="invalid-tooltip"
                        >
                          <span
                            v-if="
                              !$v.form.nameTh.required ||
                              !$v.form.nameTh.maxLength
                            "
                            >{{ error }}</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-6 col-md-3 col-xl-2">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltiplame">นามสกุล:</label>
                        <input
                          v-model="form.familyNameTh"
                          type="text"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3 col-xxl-2">
                      <div class="mb-3 position-relative">
                        <code>* </code
                        ><label for="cusSrcId"> แหล่งที่มา:</label>
                        <multiselect
                          id="cusSrcId"
                          v-model="form.cusSrcId"
                          :options="cusSrcOption"
                          :show-labels="false"
                          label="nameTh"
                          track-by="cusSrcId"
                          placeholder=""
                          @search-change="getCustomerSource($event)"
                          :class="{
                            'is-invalid': submitform && $v.form.cusSrcId.$error,
                          }"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span></multiselect
                        >
                        <div
                          v-if="submitform && $v.form.cusSrcId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.form.cusSrcId.required">{{
                            error
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3 col-xl-3 col-xxl-2">
                      <div class="mb-3 position-relative">
                        <label for="birthDate">วันเกิด:</label>

                        <date-picker
                          v-model="form.birthDate"
                          :first-day-of-week="1"
                          format="YYYY-MM-DD"
                          value-type="format"
                          lang="en"
                        >
                        </date-picker>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-xl-2">
                      <div class="mb-3 position-relative">
                        <label for="age">อายุ:</label>
                        <input
                          v-model="form.age"
                          type="text"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-xl-3 col-xxl-2">
                      <div class="mb-3 position-relative">
                        <label for="citizenId">เลขบัตรประชาชน:</label>
                        <input
                          maxlength="13"
                          v-model="form.citizenId"
                          type="text"
                          class="form-control"
                          placeholder="เลขบัตรประชาชน 13 หลัก"
                          :class="{
                            'is-invalid':
                              submitform && $v.form.citizenId.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.form.citizenId.$error"
                          class="invalid-tooltip"
                        >
                          <span
                            v-if="
                              !$v.form.citizenId.minLength ||
                              !$v.form.citizenId.maxLength ||
                              !$v.form.citizenId.numeric
                            "
                            >{{ error }}</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-6 col-md-3 col-xl-2">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltipemail">อีเมล:</label>
                        <input
                          v-model="form.email"
                          type="email"
                          class="form-control input-placeholder"
                          placeholder="ตัวอย่าง : example@mail.com"
                          :class="{
                            'is-invalid': submitform && $v.form.email.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.form.email.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.form.email.email">{{ error }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3 col-xl-2">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltipenflname">Facebook:</label>
                        <input
                          v-model="form.facebookId"
                          type="text"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3 col-xl-2">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltipenflname">Line:</label>
                        <input
                          v-model="form.lineId"
                          type="text"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3 col-xxl-2">
                      <div class="mb-3 position-relative">
                        <label for="customerType"> ประเภทลูกค้า:</label>
                        <multiselect
                          id="customerType"
                          v-model="form.customerType"
                          :options="customerTypeOption"
                          :show-labels="false"
                          label="nameTh"
                          track-by="id"
                          ><span slot="noResult">ไม่พบข้อมูล</span></multiselect
                        >
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3 col-xxl-2">
                      <div class="mb-3 position-relative">
                        <label for="lifestyleId"> ไลฟสไตล์:</label>
                        <multiselect
                          id="lifestyleId"
                          v-model="form.lifestyleId"
                          :options="lifestyleOption"
                          :show-labels="false"
                          label="nameTh"
                          track-by="lifestyleId"
                          @search-change="getLifestyle($event)"
                          ><span slot="noResult">ไม่พบข้อมูล</span></multiselect
                        >
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3 col-xl-2">
                      <div class="mb-3 position-relative">
                        <label for="occId"> สถานะ:</label>
                        <multiselect
                          id="occId"
                          v-model="form.maritalStatus"
                          :options="maritalOption"
                          :show-labels="false"
                          label="nameTh"
                          track-by="id"
                          placeholder=""
                          ><span slot="noResult">ไม่พบข้อมูล</span></multiselect
                        >
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3 col-xl-2">
                      <div class="mb-3 position-relative">
                        <label for="children">บุตร:</label>
                        <input
                          v-model="form.children"
                          type="text"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-3 col-sm-4">
                        <div class="mb-2">
                          <label class="form-label d-block mb-3"
                            >ยินยอมให้ช้ข้อมูลส่วนตัว:</label
                          >

                          <div
                            class="custom-radio form-check form-check-inline"
                          >
                            <b-form-radio
                              v-model="form.consentPrivate"
                              id="consentPrivate1"
                              name="outer-group[0][consentPrivate]"
                              class="mb-3"
                              value="1"
                              plain
                              >ยินยอม</b-form-radio
                            >
                          </div>
                          <div
                            class="custom-radio form-check form-check-inline"
                          >
                            <b-form-radio
                              v-model="form.consentPrivate"
                              id="consentPrivate2"
                              name="outer-group[1][consentPrivate]"
                              class="mb-3"
                              value="0"
                              plain
                              >ไม่ยินยอม</b-form-radio
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-4">
                        <div class="mb-2">
                          <label class="form-label d-block mb-3"
                            >ยินยอมให้ใช้ข้อมูลสำหรับทำการตลาด:</label
                          >

                          <div
                            class="custom-radio form-check form-check-inline"
                          >
                            <b-form-radio
                              v-model="form.consentMarketing"
                              id="consentMarketing1"
                              name="outer-group[0][consentMarketing]"
                              class="mb-3"
                              value="1"
                              plain
                              >ยินยอม</b-form-radio
                            >
                          </div>
                          <div
                            class="custom-radio form-check form-check-inline"
                          >
                            <b-form-radio
                              v-model="form.consentMarketing"
                              id="consentMarketing2"
                              name="outer-group[1][consentMarketing]"
                              class="mb-3"
                              value="0"
                              plain
                              >ไม่ยินยอม</b-form-radio
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3 col-sm-4">
                        <div class="mb-2">
                          <label class="form-label d-block mb-3"
                            >ยินยอมให้ใช้ข้อมูลสำคัญ:</label
                          >

                          <div
                            class="custom-radio form-check form-check-inline"
                          >
                            <b-form-radio
                              v-model="form.consentSensitive"
                              id="consentSensitive1"
                              name="outer-group[0][consentSensitive]"
                              class="mb-3"
                              value="1"
                              unchecked-value="0"
                              plain
                              >ยินยอม</b-form-radio
                            >
                          </div>
                          <div
                            class="custom-radio form-check form-check-inline"
                          >
                            <b-form-radio
                              v-model="form.consentSensitive"
                              id="consentSensitive2"
                              name="outer-group[1][consentSensitive]"
                              class="mb-3"
                              value="0"
                              plain
                              >ไม่ยินยอม</b-form-radio
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-4">
                        <div class="mb-2">
                          <label class="form-label d-block mb-3"
                            >ยินยอมให้บริษัทพันธมิตรใช้ข้อมูล:</label
                          >

                          <div
                            class="custom-radio form-check form-check-inline"
                          >
                            <b-form-radio
                              v-model="form.consentTransfer"
                              id="consentTransfer1"
                              name="outer-group[0][consentTransfer]"
                              class="mb-3"
                              value="1"
                              plain
                              >ยินยอม</b-form-radio
                            >
                          </div>
                          <div
                            class="custom-radio form-check form-check-inline"
                          >
                            <b-form-radio
                              v-model="form.consentTransfer"
                              id="consentTransfer2"
                              name="outer-group[1][consentTransfer]"
                              class="mb-3"
                              value="0"
                              plain
                              >ไม่ยินยอม</b-form-radio
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <div class="mb-3 position-relative">
                        <label for="firstContactNote"
                          >หมายเหตุในการติดต่อครั้งแรก:</label
                        >

                        <textarea
                          v-model="form.firstContactNote"
                          id="formmessage"
                          rows="3"
                          class="form-control"
                        >
                        </textarea>
                      </div>
                    </div>
                  </div>
                  <!-- end col -->

                  <!-- end row -->
                </tab-content>
                <tab-content
                  icon="mdi mdi-numeric-2"
                  title="ข้อมูลรายได้"
                  aria-selected="true"
                  aria-disabled="true"
                >
                  
                  <div class="row">
                    <div class="col-sm-6 col-md-4 col-xxl-3">
                      <div class="mb-3 position-relative">
                        <label for="srcInfoId"> ได้รับข้อมูลจากแหล่งใด:</label>
                        <multiselect
                          id="srcInfoId"
                          v-model="form.srcInfoId"
                          :options="srcInOption"
                          :show-labels="false"
                          label="nameTh"
                          track-by="nameTh"
                          @search-change="getSrcIn($event)"
                          ><span slot="noResult">ไม่พบข้อมูล</span></multiselect
                        >
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-xxl-2">
                      <div class="mb-3 position-relative">
                        <label for="salary"> ช่วงรายได้:</label>
                        <multiselect
                          id="salary"
                          v-model="form.salaryId"
                          :options="salarysOption"
                          :show-labels="false"
                          label="nameTh"
                          track-by="nameTh"
                          @search-change="getSalary($event)"
                          ><span slot="noResult">ไม่พบข้อมูล</span></multiselect
                        >
                      </div>
                    </div>
                    <!-- <div class="col-sm-6 col-md-3 col-xxl-2">
                      <div class="mb-3 position-relative">
                        <label for="salary"> รายได้:</label>
                        <multiselect
                          id="salary"
                          v-model="form.salary"
                          :options="salaryOption"
                          :show-labels="false"
                          label="nameTh"
                          track-by="nameTh"
                        ></multiselect>
                      </div>
                    </div> -->
                    <div class="col-sm-6 col-md-4 col-xxl-2">
                      <div class="mb-3 position-relative">
                        <label for="familySalaryId">
                          ช่วงรายได้ของครอบครัว:</label
                        >
                        <multiselect
                          id="familySalaryId"
                          v-model="form.familySalaryId"
                          :options="familySalaryOption"
                          :show-labels="false"
                          label="nameTh"
                          track-by="nameTh"
                          @search-change="getFamilySalary($event)"
                          ><span slot="noResult">ไม่พบข้อมูล</span></multiselect
                        >
                      </div>
                    </div>

                    <div class="col-sm-6 col-md-4 col-xxl-3">
                      <div class="mb-3 position-relative">
                        <label for="occId"> อาชีพ:</label>
                        <multiselect
                          id="occId"
                          v-model="form.occId"
                          :options="occOption"
                          :show-labels="false"
                          label="nameTh"
                          track-by="occId"
                          @search-change="getOccupations($event)"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span></multiselect
                        >
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-xxl-2">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltipenflname"
                          >สถานที่ทำงาน:</label
                        >
                        <input
                          v-model="form.workPlace"
                          type="text"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-xxl-2">
                      <div class="mb-3 position-relative">
                        <label for="regPlace"> สถานที่ลงทะเบียน:</label>
                        <multiselect
                          id="regPlace"
                          v-model="form.regPlace"
                          :options="regPlaceOption"
                          :show-labels="false"
                          label="nameTh"
                          track-by="nameTh"
                          ><span slot="noResult">ไม่พบข้อมูล</span></multiselect
                        >
                      </div>
                    </div>

                    <div class="col-sm-6 col-md-3 col-xxl-2">
                      <div class="mb-3 position-relative">
                        <label for="eventDate">วันที่จัดอีเวนต์:</label>

                        <date-picker
                          v-model="form.eventDate"
                          :first-day-of-week="1"
                          format="YYYY-MM-DD"
                          value-type="format"
                          lang="en"
                        >
                        </date-picker>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-xl-5 col-xxl-4">
                      <div class="mb-3 position-relative">
                        <label for="eventId"> อีเวนต์:</label>
                        <multiselect
                          id="eventId"
                          v-model="form.eventId"
                          :options="eventOption"
                          :show-labels="false"
                          label="nameTh"
                          track-by="nameTh"
                          @search-change="getEvent($event)"
                          ><span slot="noResult">ไม่พบข้อมูล</span></multiselect
                        >
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-xl-4 col-xxl-3">
                      <div class="mb-3 position-relative">
                        <label for="objId"> จุดประสงค์การซื้อ:</label>
                        <multiselect
                          id="objId"
                          v-model="form.objId"
                          :options="objOption"
                          :show-labels="false"
                          label="nameTh"
                          track-by="objId"
                          @search-change="getObj($event)"
                          ><span slot="noResult">ไม่พบข้อมูล</span></multiselect
                        >
                      </div>
                    </div>

                    <div class="col-sm-6 col-md-6 col-xl-3 col-xxl-3">
                      <div class="mb-3 position-relative">
                        <label for="croId"> CRO:</label>
                        <multiselect
                          id="croId"
                          v-model="form.croId"
                          :options="croOption"
                          :show-labels="false"
                          label="nameTh"
                          track-by="nameTh"
                          :custom-label="customLabelCro"
                          @search-change="getCro($event)"
                          ><span slot="noResult">ไม่พบข้อมูล</span></multiselect
                        >
                      </div>
                    </div>
                    <!-- end col -->
                  </div>
                  <!-- end row -->
                </tab-content>
                <tab-content icon="mdi mdi-numeric-3" title="ข้อมูลรถที่เคยใช้">
                  <div class="row">
                    <div class="col-sm-6 col-md-4 col-xl-4 col-xxl-3">
                      <div class="mb-3 position-relative">
                        <label for="usedBrand"> ยี่ห้อที่ใช้ในปัจจุบัน:</label>
                        <multiselect
                          id="usedBrand"
                          v-model="form.usedBrand"
                          :options="usedBrandOption"
                          :show-labels="false"
                          label="nameTh"
                          track-by="brandId"
                          @search-change="getUsedBrand($event)"
                          ><span slot="noResult">ไม่พบข้อมูล</span></multiselect
                        >
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-xl-4 col-xxl-3">
                      <div class="mb-3 position-relative">
                        <label for="otherModelId"> รุ่นอื่นๆ ที่สนใจ:</label>
                        <multiselect
                          id="otherModelId"
                          v-model="form.otherModelId"
                          :options="otherModelOption"
                          :show-labels="false"
                          label="nameTh"
                          track-by="nameTh"
                          @search-change="getOtherModel($event)"
                          ><span slot="noResult">ไม่พบข้อมูล</span></multiselect
                        >
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-xl-4 col-xxl-3">
                      <div class="mb-3 position-relative">
                        <label for="otherVehicleTypeId">
                          ประเภทรถอื่นๆ ที่สนใจ:</label
                        >
                        <multiselect
                          id="otherVehicleTypeId"
                          v-model="form.otherVehicleTypeId"
                          :options="otherVehicleOption"
                          :show-labels="false"
                          label="nameTh"
                          track-by="typeId"
                          @search-change="getOtherVehicle($event)"
                          ><span slot="noResult">ไม่พบข้อมูล</span></multiselect
                        >
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-xl-5 col-xxl-3">
                      <div class="mb-3 position-relative">
                        <label for="otherVehicleSubTypeId">
                          ประเภทย่อยของรถอื่นๆ ที่สนใจ:</label
                        >
                        <multiselect
                          id="otherVehicleSubTypeId"
                          v-model="form.otherVehicleSubTypeId"
                          :options="otherVehicleSubOption"
                          :show-labels="false"
                          label="nameTh"
                          track-by="subTypeId"
                          @search-change="getOtherVehicleSub($event)"
                          ><span slot="noResult">ไม่พบข้อมูล</span></multiselect
                        >
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-xl-4 col-xxl-3">
                      <div class="mb-3 position-relative">
                        <label for="usedVehicleTypeId">
                          ประเภทรถที่ใช้ในปัจจุบัน:</label
                        >
                        <multiselect
                          id="usedVehicleTypeId"
                          v-model="form.usedVehicleTypeId"
                          :options="usedVehicleOption"
                          :show-labels="false"
                          label="nameTh"
                          track-by="typeId"
                          @search-change="getUsedVehicle($event)"
                          ><span slot="noResult">ไม่พบข้อมูล</span></multiselect
                        >
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-xl-3 col-xxl-2">
                      <div class="mb-3 position-relative">
                        <label for="age">ใช้งานรถมาแล้ว (ปี):</label>
                        <input
                          v-model="form.vehicleLifeYear"
                          type="text"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-xl-3 col-xxl-2">
                      <div class="mb-3 position-relative">
                        <label for="vehicleLifeMonth"
                          >ใช้งานรถมาแล้ว (เดือน):</label
                        >
                        <input
                          v-model="form.vehicleLifeMonth"
                          type="text"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6 col-md-3 col-xxl-2">
                        <label class="mb-2">ระดับความสนใจ:</label>

                        <b-form-radio-group
                          v-model="form.level"
                          name="radios-stacked"
                          stacked
                        >
                          <b-form-radio value="3"
                            ><span class="ms-2"> สูง</span></b-form-radio
                          >
                          <b-form-radio value="2"
                            ><span class="ms-2"> กลาง</span></b-form-radio
                          >
                          <b-form-radio value="1"
                            ><span class="ms-2"> ต่ำ</span></b-form-radio
                          >
                          <b-form-radio value="0"
                            ><span class="ms-2"> อื่นๆ</span></b-form-radio
                          >
                        </b-form-radio-group>
                      </div>
                      <div class="col-sm-6 col-md-3 col-xxl-2">
                        <label class="mb-2">ความพร้อมในการซื้อ:</label>

                        <b-form-radio-group
                          v-model="form.readiness"
                          name="radios-stackeds"
                          stacked
                        >
                          <b-form-radio value="3"
                            ><span class="ms-2"> สูง</span></b-form-radio
                          >
                          <b-form-radio value="2"
                            ><span class="ms-2"> กลาง</span></b-form-radio
                          >
                          <b-form-radio value="1"
                            ><span class="ms-2"> ต่ำ</span></b-form-radio
                          >
                          <b-form-radio value="0"
                            ><span class="ms-2">
                              ยังไม่พร้อม</span
                            ></b-form-radio
                          >
                        </b-form-radio-group>
                      </div>
                    </div>
                  </div>
                  <!-- end row -->
                </tab-content>
                <tab-content icon="mdi mdi-numeric-4" title="ข้อมูลอื่นๆ">
                  <div class="row">
                    <div class="col-md-3 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="occId"> ประเภทที่อยู่อาศัย:</label>
                        <multiselect
                          id="occId"
                          v-model="form.residentType"
                          :options="residentOption"
                          :show-labels="false"
                          label="nameTh"
                          track-by="id"
                        ></multiselect>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltipenflname"
                          >เลขทะเบียนบ้าน:</label
                        >
                        <input
                          v-model="form.houseRegistration"
                          type="text"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-3">
                      <div class="mb-3 position-relative">
                        <label for="buildingNumber">บ้านเลขที่:</label>
                        <input
                          id="buildingNumber"
                          v-model="form.buildingNumber"
                          type="text"
                          class="form-control"
                          value=""
                        />
                        <span class="text-muted"
                          ><strong><code>999/999</code></strong></span
                        >
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-3">
                      <div class="mb-3 position-relative">
                        <label for="townshipNumber">หมู่:</label>
                        <input
                          :maxLength="10"
                          :minlength="1"
                          id="townshipNumber"
                          v-model="form.townshipNumber"
                          type="text"
                          class="form-control"
                          value=""
                        />
                        <span class="text-muted"
                          ><strong><code>ตัวอย่าง 1,2,3</code></strong></span
                        >
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="townshipName">ชื่อหมู่บ้าน:</label>
                        <input
                          id="townshipName"
                          v-model="form.townshipName"
                          type="text"
                          class="form-control"
                          value=""
                        />
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="subLane">ตรอก:</label>
                        <input
                          id="subLane"
                          v-model="form.subLane"
                          type="text"
                          class="form-control"
                          value=""
                        />
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="lane">ซอย:</label>
                        <input
                          id="lane"
                          v-model="form.lane"
                          type="text"
                          class="form-control"
                          value=""
                        />
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="streetName">ถนน:</label>
                        <input
                          id="streetName"
                          v-model="form.streetName"
                          type="text"
                          class="form-control"
                          value=""
                        />
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="provinceId">จังหวัด:</label>
                        <multiselect
                          @input="getAmphures(provinceId)"
                          id="provinceId"
                          v-model="provinceId"
                          :options="provinces"
                          :show-labels="false"
                          label="nameTh"
                          track-by="nameTh"
                        ></multiselect>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="amphurId">อำเภอ:</label>
                        <multiselect
                          @input="getDistricts(amphurId)"
                          id="amphurId"
                          v-model="amphurId"
                          :options="amphures"
                          :show-labels="false"
                          label="nameTh"
                          track-by="nameTh"
                        ></multiselect>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="districtId">ตำบล:</label>
                        <multiselect
                          id="districtId"
                          v-model="districtId"
                          :options="districts"
                          :show-labels="false"
                          label="nameTh"
                          track-by="nameTh"
                        ></multiselect>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="postCode">รหัสไปรษณีย์:</label>
                        <input
                          id="postCode"
                          v-model="form.postCode"
                          maxlength="5"
                          type="text"
                          class="form-control"
                          value=""
                          :class="{
                            'is-invalid': submitform && $v.form.postCode.$error,
                          }"
                        />

                        <div
                          v-if="submitform && $v.form.postCode.$error"
                          class="invalid-tooltip"
                        >
                          <span
                            v-if="
                              !$v.form.postCode.numeric ||
                              !$v.form.postCode.minLength ||
                              !$v.form.postCode.maxLength
                            "
                            >{{ error }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <div class="mb-3 position-relative">
                        <label for="fullAddress">ที่อยู่เต็ม:</label>
                        <!-- disabled="disabled"  -->
                        <textarea
                          disabled
                          v-model="fullAddress"
                          class="form-control"
                          name="textarea"
                          rows="3"
                        ></textarea>
                        <p></p>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltipnote">หมายเหตุ:</label>

                        <textarea
                          v-model="form.note"
                          id="formmessage"
                          rows="3"
                          class="form-control"
                        >
                        </textarea>
                      </div>
                    </div>
                  </div>

                  <!-- end row -->
                </tab-content>
                <template slot="footer" slot-scope="props">
                  <div class="wizard-footer-left">
                    <button
                      v-if="props.activeTabIndex > 0"
                      :style="props.fillButtonStyle"
                      @click="props.prevTab()"
                      class="btn"
                    >
                      ย้อนกลับ
                    </button>
                  </div>
                  <div class="wizard-footer-right">
                    <button
                      v-if="!props.isLastStep"
                      @click="validateNextTab(props)"
                      class="btn"
                      :style="props.fillButtonStyle"
                    >
                      ต่อไป
                    </button>
                  </div>
                  <div class="wizard-footer-right">
                    <button type="submit" class="btn btn-success" @click="Form">
                      บันทึก
                    </button>
                    &nbsp;
                  </div>
                </template>
              </form-wizard>
              <!-- <div class="text-end br">
                <button type="submit" class="btn btn-success" @click="Form">
                  บันทึก
                </button>
                &nbsp;
              </div> -->
            </div>
          </div>

          <hr />
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import {
  required,
  email,
  minLength,
  // sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  // url,
  // alphaNum,
} from "vuelidate/lib/validators";

import Swal from "sweetalert2";
import useNetw from "@useNetw";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    FormWizard,
    TabContent,
    DatePicker,
    /*Switches,
    NumberInputSpinner,*/
  },
  page: {
    title: appConfig.prospectCustomer,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  data() {
    return {
      Hide: false,
      enabled: false,
      submitform: false,
      submitformAddress: false,
      modalUpdateAddress: false,
      submitUpdateAddress: false,

      title: "เพิ่มข้อมูลลูกค้ามุ่งหวัง",
      items: [
        {
          text: "ลูกค้าสัมพันธ์",
          active: true,
        },
        {
          text: appConfig.prospectCustomer,
          href: "/prospect-customer",
        },
        {
          text: "เพิ่มข้อมูลลูกค้ามุ่งหวัง",
          active: true,
        },
      ],
      localDataBranch: [], //เป็น-array Id
      localData: [],
      value: null,
      value1: null,
      dataTest: [],
      options: [],

      startIndex: "",
      endIndex: "",
      loading: undefined,
      total_pages: "",
      page: "",
      per_page: "",
      totalRecord: "",
      rowData: [], //? Data ที่ต่อ API
      // rowUser: [],
      rowAddress: [],
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      paginatedData: "",

      selectedUsers: [],
      isSelectedAll: false,

      selectMode: "multi",
      selected: [],

      fields: [
        {
          key: "buildingNumber",
          sortable: true,
          label: "บ้านเลขที่",
        },
        {
          key: "districtNameTh",
          sortable: true,
          label: "ตำบล",
        },
        {
          key: "amphurNameTh",
          sortable: true,
          label: "อำเภอ",
        },
        {
          key: "provinceNameTh",
          sortable: true,
          label: "จังหวัด",
        },
        {
          key: "action",
          label: "แก้ไข | ลบ ",
        },
      ],

      error: "กรุณาตรวจสอบข้อมูลอีกครั้ง.",
      readinessOption: [
        { text: "สูง", value: "3" },
        { text: "กลาง", value: "2" },
        { text: "ต่ำ", value: "1" },
        { text: "ยังไม่พร้อม", value: "0" },
      ],
      lavelOption: [
        { text: "สูง", value: "3" },
        { text: "กลาง", value: "2" },
        { text: "ต่ำ", value: "1" },
        { text: "อื่นๆ", value: "0" },
      ],

      occOption: [],
      salaryOption: [
        { nameTh: "ต่ำกว่า 10,000", id: 0 },
        { nameTh: "10,001-30,000", id: 1 },
        { nameTh: "30,001-50,000", id: 2 },
        { nameTh: "50,001-100,000", id: 3 },
        { nameTh: "100,000 บาทขึ้นไป", id: 4 },
      ],
      cusSrcOption: [],
      prefixOPtion: [],
      provinces: [],
      amphures: [],
      districts: [],
      residentOption: [
        { nameTh: "เช่า", id: "R" },
        { nameTh: "อาศัยอยู่บ้านตัวเอง", id: "H" },
        { nameTh: "อาศัยอยู่กับญาติ", id: "RH" },
        { nameTh: "อาศัยอยู่กับผู้อื่น", id: "OH" },
      ],
      maritalOption: [
        { nameTh: "โสด", id: "S" },
        { nameTh: "แต่งงานแล้ว", id: "M" },
        { nameTh: "หย่าร้าง", id: "D" },
        { nameTh: "หม้าย", id: "W" },
      ],
      genderOption: [
        { nameTh: "ชาย", id: "M" },
        { nameTh: "หญิง", id: "F" },
        { nameTh: "ไม่ระบุ", id: "NONE" },
      ],
      salarysOption: [],
      eventOption: [],
      srcInOption: [],
      usedBrandOption: [],
      lifestyleOption: [],
      objOption: [],
      familySalaryOption: [],
      croOption: [],
      otherModelOption: [],
      otherVehicleOption: [],
      otherVehicleSubOption: [],
      usedVehicleOption: [],
      customerTypeOption: [
        { nameTh: "ใช้งานส่วนบุคคล ", id: "P" },
        { nameTh: "ใช้งานเชิงธุรกิจ", id: "B" },
      ],
      regPlaceOption: [{ nameTh: "Showroom " }, { nameTh: "Road Show" }],
      hideButton: false,
      form: {
        readiness: "",
        lineId: "",
        facebookId: "",
        houseRegistration: "",
        workPlace: "",
        maritalStatus: "",
        cusId: "",
        residentType: "",
        branchId: "",
        cusType: "",
        groupId: "",
        citizenId: "",
        prefixId: "",
        prefixIdEn: "",
        nameTh: "",
        familyNameTh: "",
        nameEn: "",
        familyNameEn: "",
        birthDate: "",
        mobilePhone: "",
        email: "",
        discount: "",
        credit: "",
        note: "",
        accountPayable: "",
        postCode: "",
        lane: "",
        subLane: "",
        townshipName: "",
        townshipNumber: "",
        buildingNumber: "",
        cusSrcId: "",
        salary: "",
        occId: "",
        level: "2",
        salaryId: "",
        eventId: "",
        srcInfoId: "",
        usedBrand: "",
        lifestyleId: "",
        objId: "",
        familySalaryId: "",
        croId: "",
        otherModelId: "",
        otherVehicleTypeId: "",
        otherVehicleSubTypeId: "",
        usedVehicleTypeId: "",
        vehicleLifeYear: "",
        vehicleLifeMonth: "",
        gender: "",
        children: "",
        age: "",
        customerType: "",
        regPlace: "",
        consentPrivate: "1",
        consentMarketing: "1",
        consentSensitive: "1",
        consentTransfer: "1",
        eventDate: "",
        firstContactNote: "",
      },
      provinceId: "",
      amphurId: "",
      districtId: "",
      addressform: {
        buildingNumber: "", //บ้านเลขที่
        townshipNumber: "", //หมู่
        townshipName: "", //ชื่อหมู่บ้าน
        // subLane: "", //ตรอก
        lane: "", //ซอย
        streetName: "", //ชื่อถนน
        districtId: "", //ตำบล
        amphureId: "", //อำเภอ
        provinceId: "", //จังหวัด
        postCode: "", //รหัสไปรษณีย์
        roomnumber: "", //เลขห้อง
        fullAddress: "", // รวมที่อยู่
        provinces: [], //รับค่าตัวแปรจังหวัด
        amphures: [], //รับค่าตัวแปรอำเภอ
        districts: [], //รับค่าตำบล
        buildingName: "", //ชื่ออาคาร

        // road: "",
      },
      UpdateAddress: {
        addrIdU: "",

        buildingNumberU: "", //บ้านเลขที่
        townshipNumberU: "", //หมู่
        townshipNameU: "", //ชื่อหมู่บ้าน
        // subLaneU: "",
        laneU: "", //ซอย
        streetNameU: "", //ชื่อถนน
        districtIdU: "", //ตำบล
        amphurIdU: "", //อำเภอs
        provinceIdU: "", //จังหวัด
        postCodeU: "", //รหัสไปรษณีย์
        // roomnumberU: "", //เลขห้องฝ
        fullAddressU: "", // รวมที่อยู่
        provinces: [], //รับค่าตัวแปรจังหวัด
        amphures: [], //รับค่าตัวแปรอำเภอ
        districts: [], //รับค่าตำบล
        buildingNameU: "", //ชื่ออาคาร
      },
    };
  },
  validations: {
    form: {
      branchId: {
        required,
      },
      nameTh: {
        required,
      },

      citizenId: {
        numeric,
        minLength: minLength(13),
        maxLength: maxLength(13),
      },
      mobilePhone: {
        required,
        numeric,
        maxLength: maxLength(10),
        minLength: minLength(10),
      },
      email: {
        email,
        maxLength: maxLength(255),
      },
      postCode: {
        numeric,
        minLength: minLength(5),
        maxLength: maxLength(5),
      },
      cusSrcId: {
        required,
      },
    },

    addressform: {
      buildingNumber: {
        required,
      },
      townshipNumber: {},
      buildingName: {},
      roomnumber: {
        minValue: minValue(1),
        maxValue: maxValue(10),
      },
      townshipName: {
        maxLength: maxLength(50),
      },
      lane: {
        maxLength: maxLength(50),
      },
      streetName: {
        maxLength: maxLength(50),
      },
      provinceId: {
        required,
      },
      amphureId: {
        required,
      },
      districtId: {
        required,
      },
      postCode: {},
    },

    UpdateAddress: {
      buildingNumberU: {
        required,
      },
      townshipNumberU: {},
      townshipNameU: {},
      // subLaneU: {

      // },
      laneU: {},
      streetNameU: {},
      // roomnumberU: {
      //   minValue: minValue(1),
      //   maxValue: maxValue(10),
      // },
      districtIdU: {
        required,
      },
      amphurIdU: {
        required,
      },
      provinceIdU: {
        required,
      },
      postCodeU: {},
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
      if (user.branchId == item.branchId) {
        this.form.branchId = {
          branchId: item.branchId,
          nameTh: item.nameTh,
        };
      }
    });
    this.localDataBranchIdUser = arrayBranch;
    this.branchId = user.branchId;
    this.localData = user.userAccessBranch;

    this.getPrefixes();
    this.getProvinces();
    this.getSearch();
  },
  computed: {
    fullAddress: function () {
      //

      return `${
        this.form.buildingNumber != ""
          ? "" + this.form.buildingNumber
          : this.form.buildingNumber
      } ${
        this.form.townshipNumber != ""
          ? "หมู่" + " " + this.form.townshipNumber
          : this.form.townshipNumber
      } ${
        this.form.townshipName != ""
          ? "" + this.form.townshipName
          : this.form.townshipName
      } ${
        this.form.subLane != ""
          ? "ตรอก" + " " + this.form.subLane
          : this.form.subLane
      } ${
        this.form.lane != "" ? "ซอย" + " " + this.form.lane : this.form.lane
      } ${
        this.form.streetName != "" && this.form.streetName != undefined
          ? "ถนน" + " " + this.form.streetName
          : ""
      } ${
        this.districtId != "" &&
        this.districtId != undefined &&
        this.districtId != null &&
        this.districtId.nameTh != "" &&
        this.districtId.nameTh != undefined &&
        this.districtId.nameTh != null
          ? this.districtId.nameTh
          : ""
      } ${
        this.amphurId != "" &&
        this.amphurId != undefined &&
        this.amphurId != null &&
        this.amphurId.nameTh != "" &&
        this.amphurId.nameTh != undefined &&
        this.amphurId.nameTh != null
          ? this.amphurId.nameTh
          : ""
      } ${
        this.provinceId != "" &&
        this.provinceId != null &&
        this.provinceId != undefined &&
        this.provinceId.nameTh != null &&
        this.provinceId.nameTh != "" &&
        this.provinceId.nameTh != undefined
          ? this.provinceId.nameTh
          : ""
      }  ${
        this.form.postCode != ""
          ? "รหัสไปรษณีย์" + " " + this.form.postCode
          : ""
      }
      `;
    },
  },
  created() {
    // this.getLocalData();
  },
  methods: {
    validateNextTab(props) {
      this.$v.$touch();
      this.submitform = true;
      if (this.$v.form.$invalid != true) {
        props.nextTab();
      }
    },
    customLabelCro({ nameTh, familyName }) {
      return `${nameTh}  ${familyName ? familyName : ""}`;
    },
    customLabel({ nameTh, familyName }) {
      return `${nameTh}  ${familyName ? familyName : ""}`;
    },
    max() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const maxDate = new Date(today);

      maxDate.setDate(0);
      // console.log(maxDate);
    },
    ChangeNameTh(nameTh) {
      if (nameTh.nameTh === "นาย") {
        this.form.prefixIdEn = "Mr.";
      } else if (nameTh.nameTh === "นาง") {
        this.form.prefixIdEn = "Mrs.";
      } else {
        this.form.prefixIdEn = "Miss.";
      }
    },
    getSearch() {
      this.getCustomerSource();
      this.getOccupations();
      this.getSalary();
      this.getEvent();
      this.getSrcIn();
      this.getUsedBrand();
      this.getLifestyle();
      this.getObj();
      this.getFamilySalary();
      this.getCro();
      this.getOtherModel();
      this.getOtherVehicle();
      this.getOtherVehicleSub();
      this.getUsedVehicle();
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchItem = user.branchId;

      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });

      this.localDataBranch = localDataBranch;
    },

    Form() {
      this.$v.$touch();
      this.submitform = true;
      if (this.$v.form.$invalid != true) {
        this.postProspectCustomer();
      }
    },
    getOccupations: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/prospect-customer/branch-occupation", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId:
              this.form.branchId !== null
                ? this.form.branchId.branchId
                : this.branchId,
            nameTh: event,
          },
        })
        .then((response) => {
          this.occOption = response.data.data;
          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / this.perPage);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getCustomerSource: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/prospect-customer/branch-customer-source", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId:
              this.form.branchId !== null
                ? this.form.branchId.branchId
                : this.branchId,
            nameTh: event,
          },
        })
        .then((response) => {
          this.cusSrcOption = response.data.data;
          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / this.perPage);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getSalary: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/prospect-customer/branch-salary", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId:
              this.form.branchId !== null
                ? this.form.branchId.branchId
                : this.branchId,
            nameTh: event,
          },
        })
        .then((response) => {
          this.salarysOption = response.data.data;
          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / this.perPage);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getEvent: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/prospect-customer/branch-sales-event", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId:
              this.form.branchId !== null
                ? this.form.branchId.branchId
                : this.branchId,
            nameTh: event,
          },
        })
        .then((response) => {
          this.eventOption = response.data.data;
          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / this.perPage);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getSrcIn: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/prospect-customer/branch-customer-source-info", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId:
              this.form.branchId !== null
                ? this.form.branchId.branchId
                : this.branchId,
            nameTh: event,
          },
        })
        .then((response) => {
          this.srcInOption = response.data.data;
          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / this.perPage);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getUsedBrand: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/vehicle/brands", {
          params: {
            nameTh: event,
          },
        })
        .then((response) => {
          this.usedBrandOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getLifestyle: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/lifestyles", {
          params: {
            nameTh: event,
          },
        })
        .then((response) => {
          this.lifestyleOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getObj: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/buyer-objectives", {
          params: {
            nameTh: event,
          },
        })
        .then((response) => {
          this.objOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getFamilySalary: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/prospect-customer/branch-salary", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId:
              this.form.branchId !== null
                ? this.form.branchId.branchId
                : this.branchId,
            nameTh: event,
          },
        })
        .then((response) => {
          this.familySalaryOption = response.data.data;
          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / this.perPage);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getCro: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/prospect-customer/branch-user", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId:
              this.form.branchId !== null
                ? this.form.branchId.branchId
                : this.branchId,
            name: event,
          },
        })
        .then((response) => {
          this.croOption = response.data.data;
          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / this.perPage);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getOtherModel: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/prospect-customer/dealer-other-vehicle-model", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId:
              this.form.branchId !== null
                ? this.form.branchId.branchId
                : this.branchId,
            nameTh: event,
          },
        })
        .then((response) => {
          this.otherModelOption = response.data.data;
          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / this.perPage);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getOtherVehicle: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/vehicle/types", {
          params: {
            nameTh: event,
          },
        })
        .then((response) => {
          this.otherVehicleOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getOtherVehicleSub: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/vehicle-subtypes", {
          params: {
            nameTh: event,
          },
        })
        .then((response) => {
          this.otherVehicleSubOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getUsedVehicle: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/vehicle/types", {
          params: {
            nameTh: event,
          },
        })
        .then((response) => {
          this.usedVehicleOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getAddress();
      this.startIndex = (this.currentPage - 1) * this.perPage + 1;
      this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;
      if (this.endIndex > this.totalRecord) {
        this.endIndex = this.totalRecord;
      } else {
        return this.endIndex;
      }
      this.paginatedData = this.rowAddress.slice(
        this.startIndex,
        this.endIndex
      );
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getAddress();
      this.startIndex = (this.currentPage - 1) * this.perPage + 1;
      this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;

      if (this.endIndex > this.totalRecord) {
        this.endIndex = this.totalRecord;
      } else {
        return this.endIndex;
      }

      this.paginatedData = this.rowAddress.slice(
        this.startIndex,
        this.endIndex
      );
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.rowAddress = filteredItems.length;
      this.currentPage = 1;
    },

    onRowSelected(rowData) {
      this.selected = rowData;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },

    getProvinces: function () {
      //  if(this.provinceId != null && this.provinceId != "" && this.provinceId != undefined) {
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/address/provinces", {})
        .then((response) => {
          this.provinces = response.data.data;
          // console.log( this.provinces);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
      //  }
    },
    getAmphures: function (isReset) {
      if (
        this.provinceId != "" &&
        this.provinceId != undefined &&
        this.provinceId != null
      ) {
        this.loading = true; //skeleton true
        useNetw
          .get("api/master/address/amphures", {
            params: {
              provinceId: this.provinceId ? this.provinceId.provinceId : "",
            },
          })
          .then((response) => {
            this.amphures = response.data.data;

            if (isReset) {
              this.amphurId = "";
              this.districtId = "";
            }
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {
            // this.overlayFlag = false;
            this.loading = false; //skeleton false
          });
      }
    },
    getDistricts: function (isReset) {
      if (
        this.provinceId != "" &&
        this.provinceId != undefined &&
        this.provinceId != null &&
        this.amphurId != null &&
        this.amphurId != ""
      ) {
        useNetw
          .get("api/master/address/districts", {
            params: {
              amphurId: this.amphurId ? this.amphurId.amphureId : "",
            },
          })
          .then((response) => {
            this.districts = response.data.data;
            if (isReset) {
              this.districtId = "";
            }
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {});
      }
    },

    getPrefixes: function () {
      useNetw
        .get("api/master/person/prefixes", {})
        .then((response) => {
          this.prefixOPtion = response.data.data;
          // console.log(this.prefixOPtion);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },

    postProspectCustomer: function () {
      this.overlayFlag = true;

      useNetw
        .post("api/prospect-customer/store", {
          branchId: this.form.branchId.branchId,
          citizenId: this.form.citizenId,
          prefixId:
            this.form.prefixId.prefixId != undefined
              ? this.form.prefixId.prefixId
              : "",
          nameTh: this.form.nameTh,
          familyNameTh: this.form.familyNameTh,
          nameEn: this.form.nameEn,
          familyNameEn: this.form.familyNameEn,
          birthDate: this.form.birthDate,
          mobilePhone: this.form.mobilePhone,
          email: this.form.email,
          note: this.form.note,
          buildingNumber: this.form.buildingNumber,
          townshipNumber: this.form.townshipNumber,
          townshipName: this.form.townshipName,
          subLane: this.form.subLane,
          lane: this.form.lane,
          streetName: this.form.streetName,
          districtId: this.districtId ? this.districtId.districtId : "",
          amphurId: this.amphurId ? this.amphurId.amphureId : "",
          provinceId: this.provinceId ? this.provinceId.provinceId : "",
          postCode: this.form.postCode,
          fullAddress: this.fullAddress,
          cusSrcId: this.form.cusSrcId ? this.form.cusSrcId.cusSrcId : "",
          salary: this.form.salary.id ? this.form.salary.id : "",
          occId: this.form.occId ? this.form.occId.occId : "",
          level: this.form.level,
          residentType: this.form.residentType ? this.form.residentType.id : "",
          maritalStatus: this.form.maritalStatus
            ? this.form.maritalStatus.id
            : "",
          workPlace: this.form.workPlace,
          houseRegistration: this.form.houseRegistration,
          facebookId: this.form.facebookId,
          lineId: this.form.lineId,
          readiness: this.form.readiness,
          salaryId: this.form.salaryId ? this.form.salaryId.salaryId : "",
          eventId: this.form.eventId ? this.form.eventId.eventId : "",
          srcInfoId: this.form.srcInfoId ? this.form.srcInfoId.srcInfoId : "",
          usedBrand: this.form.usedBrand ? this.form.usedBrand.brandId : "",
          lifestyleId: this.form.lifestyleId
            ? this.form.lifestyleId.lifestyleId
            : "",
          objId: this.form.objId ? this.form.objId.objId : "",
          familySalaryId: this.form.familySalaryId
            ? this.form.familySalaryId.salaryId
            : "",
          croId: this.form.croId ? this.form.croId.userId : "",
          otherModelId: this.form.otherModelId
            ? this.form.otherModelId.modelId
            : "",
          otherVehicleTypeId: this.form.otherVehicleTypeId
            ? this.form.otherVehicleTypeId.typeId
            : "",
          otherVehicleSubTypeId: this.form.otherVehicleSubTypeId
            ? this.form.otherVehicleSubTypeId.subTypeId
            : "",
          usedVehicleTypeId: this.form.usedVehicleTypeId
            ? this.form.usedVehicleTypeId.typeId
            : "",
          vehicleLifeYear: this.form.vehicleLifeYear,
          vehicleLifeMonth: this.form.vehicleLifeMonth,
          gender: this.form.gender ? this.form.gender.id : "",
          children: this.form.children,
          age: this.form.age,
          customerType: this.form.customerType ? this.form.customerType.id : "",
          regPlace: this.form.regPlace.nameTh,
          consentPrivate: this.form.consentPrivate,
          consentMarketing: this.form.consentMarketing,
          consentSensitive: this.form.consentSensitive,
          consentTransfer: this.form.consentTransfer,
          eventDate: this.form.eventDate,
          firstContactNote: this.form.firstContactNote,
        })
        .then((response) => {
          // this.getUser();
          //con
          this.cusIdPost = response.data.cusId;
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );

          this.$router.push({
            name: "edit-prospectCustomer",
            params: {
              ppCusId: btoa(response.data.ppCusId),
            },
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    clearAddressPost() {
      this.addressform.buildingNumber = "";
      this.addressform.townshipNumber = "";
      this.addressform.townshipName = "";
      this.addressform.subLane = "";
      this.addressform.lane = "";
      this.addressform.streetName = "";
      this.addressform.districtId = "";
      this.addressform.amphurId = "";
      this.addressform.provinceId = "";
      this.addressform.postCode = "";
      // this.fullAddress = "";
      this.submitformAddress = false;
    },
  },
};
</script>
<style scoped>
.input-placeholder {
  font-size: 12px;
}
.br {
  padding-right: 20px;
}
</style>
